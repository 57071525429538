.mainNavbar {
  z-index: 999;
  width: 100%;
  background-color: #ffffff;
  max-height: 140px;
}

.mainLogo {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: #ffffff;
  margin-right: 1.5rem;
}

.headerMenuItem {
  position: relative;
  display: block;
  margin-top: 1rem;
  margin-right: 0.25rem;
  color: #565660;
  font-size: 15px;
}

.headerMenuItemSignIn {
  color: #443eff;
}

.headerMenuItemActive {
  color: #443eff;
  font-weight: bold;
}

.headerMenuItemActive.headerMenuItem:after,
.headerMenuItem-.headerMenuItem:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  bottom: -2px;
  border-bottom: 2px solid #443eff;
}

.navbarBgOverlay {
  z-index: 998;
  position: fixed;
  height: 100%;
  width: 100%;
}

.navHamburgerIcon {
  position: absolute;
  right: 10px;
}

@media (min-width: 768px) {
  .headerMenuItem {
    display: inline-block;
    margin-top: 0px;
  }
}

.topCta {
  box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.33),
    0px 0px 1px rgba(130, 136, 148, 0.26);
  border-radius: 6px;
}

.invertLogo {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 1024px) {
  .primaryMenu {
    position: fixed;
    top: 68px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: hidden;
    transition: opacity 0.4s, visibility 0.4s, width 0.4s;
    z-index: 50;
  }
  .primaryMenu:after {
    background-color: #ffffff;
    content: '';
    right: -100%;
    top: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all 0.1s ease;
  }
  .primaryMenu.menuOpen {
    opacity: 1;
    visibility: visible;
    height: 100%;
  }
  .primaryMenu.menuOpen:after {
    right: 0;
  }
  .menuLeft {
    z-index: 100;
    position: relative;
  }
  .menuList > li,
  .navContact {
    margin-left: 70px;
    display: block;
    opacity: 0;
    color: #565660;
  }
  .primaryMenu.menuOpen .menuLeft .menuContainer .menuList li,
  .primaryMenu.menuOpen .menuLeft .menuContainer .navContact {
    margin-left: 0;
    opacity: 1;
  }
  .primaryMenu.menuOpen .menuLeft .menuContainer .menuList li:nth-child(1) {
    transition: all 0.1s;
    transition-delay: 0.1s;
  }
  .primaryMenu.menuOpen .menuLeft .menuContainer .menuList li:nth-child(2) {
    transition: all 0.15s;
    transition-delay: 0.15s;
  }
  .primaryMenu.menuOpen .menuLeft .menuContainer .menuList li:nth-child(3) {
    transition: all 0.18s;
    transition-delay: 0.18s;
  }
  .primaryMenu.menuOpen .menuLeft .menuContainer .menuList li:nth-child(4) {
    transition: all 0.21s;
    transition-delay: 0.21s;
  }
  .primaryMenu.menuOpen .menuLeft .menuContainer .menuList li:nth-child(5) {
    transition: all 0.25s;
    transition-delay: 0.25s;
  }
  .primaryMenu.menuOpen .menuLeft .menuContainer .menuList li:nth-child(6) {
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
  .primaryMenu.menuOpen .menuLeft .menuContainer .menuList li:nth-child(7) {
    transition: all 0.35s;
    transition-delay: 0.35s;
  }
  .primaryMenu.menuOpen .menuLeft .menuContainer .menuList li:nth-child(8) {
    transition: all 0.38s;
    transition-delay: 0.38s;
  }
  .primaryMenu.menuOpen .menuLeft .menuContainer .navContact {
    transition: all 0.25s;
    transition-delay: 0.3s;
  }
  .menuLeft > li > a {
    margin-left: 1.5rem !important;
  }
  .menuList > li {
    padding: 10px 0;
  }
  .menuList > li > a {
    font-size: 18px;
    font-weight: bold;
    margin-left: 1.5rem;
  }
  .navContact {
    margin-top: 40px;
  }
  .navContact > h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .navContact > a {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
  .navContact > a > img {
    width: 20px;
    height: 20px;
  }
  .navContact > a > p {
    font-size: 16px;
  }
}

/* nav bar height adjusting current header design for ipad pro */
@media (width: 1024px) {
  /* .main-navbar{
      height:120px
    } */
}

/* new website nav design */
.desktopNav {
  display: none;
}

@media (min-width: 1025px) {
  .desktopNav {
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.newNav {
  height: 100%;
}

.newNav > ul {
  list-style: none;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.newNav > ul > li {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes bounceup {
  0% {
    top: 120%;
  }
  100% {
    top: 100%;
  }
}
.hoverableNavDropdown {
  min-width: 130px;
  /* min-height: 200px; */
  background: white;
  position: absolute;
  top: 100%;
  display: none;
  opacity: 0;
  animation: fadein 0.5s ease-in-out;
  animation: bounceup 0.2s ease-in;
  border: 1px solid lightgray;
  border-left: 5px solid #443eff;
  border-radius: 4px;
  z-index: 1;
  white-space: nowrap;
}

.hoverableNav:hover .hoverableNavDropdown {
  display: block;
  opacity: 1;
}

.hoverableNav {
  position: relative;
}

.hoverableNavDropdown > ul > li {
  padding: 1rem 1rem;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.hoverableNavDropdown > ul > li:last-child {
  border-bottom: none;
  padding-bottom: none;
}
.hoverableNavDropdown > ul > li:hover {
  color: #443eff;
}

.hoverableNavDropdown > ul > li > a {
  display: block;
  width: 100%;
}
.hoverableNav .hoverableNavArrow {
  width: 0;
  height: 0;
  border: solid #6b6767;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  -webkit-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
  margin-bottom: 4px;
  margin-left: 2px;
}

/* show extra features in list */
.showExtraFeatureDropDown {
  min-width: 166px;
  min-height: 212px;
  padding: 0rem 1rem 0 1rem;
  position: absolute;
  top: 0%;
  display: none;
  opacity: 0;
  animation: fadein 0.5s ease-in-out;
  /* animation: bounceright 0.2s ease-in; */
  background: #efeffc 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 25px #0000000b;
  border-radius: 4px;
  white-space: nowrap;
  transform: translateX(87%);
  color: #565660;
  font-weight: normal;
}

.showExtraFeatureDropDown:hover {
  display: block;
  opacity: 1;
}

.showExtraFeature:hover .showExtraFeatureDropDown {
  display: block;
  opacity: 1;
}


.showExtraFeatureDropDown > ul > li {
  padding: 1rem 0rem;
  border-bottom: 1px solid #c7c7c7;
  cursor: pointer;
}
.showExtraFeatureDropDown > ul > li:last-child {
  border-bottom: none;
  padding-bottom: none;
}
.showExtraFeatureDropDown > ul > li:hover {
  color: #443eff;
}

.showExtraFeatureDropDown > ul > li > a {
  display: block;
  width: 100%;
}
.showExtraFeature .showExtraFeatureRightArrow {
  width: 0;
  height: 0;
  border: solid #6b6767;
  border-width: 2px 2px 0px 0px;
  display: inline-block;
  padding: 4px;
  -webkit-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
  margin-bottom: 4px;
  margin-left: 2px;
}

.showExtraFeature:hover .showExtraFeatureRightArrow {
  transition: all ease-in-out 0.3s;
  -webkit-transform: rotate(225deg) !important;
  transform: rotate(225deg) !important;
  margin-bottom: 0px;
}

@keyframes rotateSemi {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.hoverableNav:hover .hoverableNavArrow {
  transition: all ease-in-out 0.3s;
  -webkit-transform: rotate(225deg) !important;
  transform: rotate(225deg) !important;
  margin-bottom: 0px;
}

.hoverableNav:hover .headerMenuItem {
  color: #443eff;
}
.desktopHide {
  /* display: in; */
}
@media (min-width: 1025px) {
  .desktopHide {
    display: none !important;
  }
}

/* mobile css */

.navEduloanMbile {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem !important;
  font-size: 18px;
  font-weight: bold;
  margin-left: 1.5rem;
}

.navArrowMobile {
  border: solid #6b6767;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
}
.mobileDropdown {
  background: #f4f4f4;
  margin: 1rem 0;
  padding-left: 1rem;
}
.mobileDropdown > li {
  padding: 1rem;
  position: relative;
  color: #565660;
  font-weight: bold;
}

.mobileDropdown > li:after {
  content: '';
  position: absolute;
  width: 90%;
  left: 3%;
  bottom: 0%;
  height: 1px;
  background: lightgray;
}
.mobileDropdown > li:last-child:after {
  height: 0;
}

.navContact {
  padding: 0 1.5rem !important;
}

.navArrowMobile.arrowUp {
  border: solid #443eff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(225deg) !important;
  transform: rotate(225deg) !important;
  transition: all ease-in-out 0.3s;
}
.navArrowMobile.arrowDown {
  -webkit-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
  transition: all ease-in-out 0.3s;
}

.headerMenuItem:hover {
  color: #443eff;
}
.navEduloanMbileCountry {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem !important;
}

.countryDropDown {
  margin: 1rem 0;
  padding-left: 2rem;
  color: #565660;
}
.countryDropDown > li {
  padding: .4em;
  position: relative;
  font-weight: bold;
  font-size: 14px;
}

.countryDropDown > li:after {
  content: '';
  position: absolute;
  width: 90%;
  left: 3%;
  bottom: 0%;
  height: 1px;
}
.countryDropDown > li:last-child {
  height: 0;
  /* padding-left: 2em; */
}
